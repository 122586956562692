  import React, { useState, useEffect } from 'react';
  import { motion } from 'framer-motion';
  import CountUp from 'react-countup';
  import './StockMarketTracker.css';// Ensure your CSS is correctly linked

  const crownIcon = "/images/crownlargeyellow.svg";

  const logoMap = {
    AAPL: "/logos/AAPL.svg",
    MSFT: "/logos/MSFT.svg",
    TSLA: "/logos/TSLA.svg",
    AMZN: "/logos/AMZN.svg",
    GOOG: "/logos/GOOG.svg",
    NVDA: "/logos/NVDA.svg",
    META: "/logos/META.svg",
    LLY: "/logos/LLY.svg",
    COST: "/logos/COST.svg",
    V: "/logos/V.svg",
    WMT: "/logos/WMT.svg",
    AVGO: "/logos/AVGO.svg",
    UNH: "/logos/UNH.svg",
    NVO: "/logos/NVO.svg",
    TSM: "/logos/TSM.svg",
    XOM: "/logos/XOM.svg",
    JPM: "/logos/JPM.svg",
    HD: "/logos/HD.svg",
    ORCL: "/logos/ORCL.svg",
    PG: "/logos/PG.svg",
    MA: "/logos/MA.svg",
    JNJ: "/logos/JNJ.svg",
    ABBV: "/logos/ABBV.svg",
    BAC: "/logos/BAC.svg",
    NFLX: "/logos/NFLX.svg",
    KO: "/logos/KO.svg",
    CRM: "/logos/CRM.svg",
    MRK: "/logos/MRK.svg",
    CVX: "/logos/CVX.svg",
    AMD: "/logos/AMD.svg"
  };

  const colorMap = {
    AAPL: "#ffcc00",  // Yellow for Apple
    MSFT: "#0078d7",  // Blue for Microsoft
    TSLA: "#e31937",  // Red for Tesla
    AMZN: "#ff9900",  // Orange for Amazon
    NVDA: "#76b900",  // Green for Nvidia
    META: "#4267B2",  // Blue for Meta
    LLY: "#FF001F",   // Red for Eli Lilly
    COST: "#ffcc00",  // Yellow for Apple
    V: "#0078d7",  // Blue for Microsoft
    WMT: "#e31937",  // Red for Tesla
    AVGO: "#ff9900",  // Orange for Amazon
    UNH: "#76b900",  // Green for Nvidia
    NVO: "#4267B2",  // Blue for Meta
    TSM: "#FF001F",   // Red for Eli Lilly
    XOM: "#ffcc00",  // Yellow for Apple
    JPM: "#0078d7",  // Blue for Microsoft
    HD: "#e31937",  // Red for Tesla
    ORCL: "#ff9900",  // Orange for Amazon
    PG: "#76b900",  // Green for Nvidia
    MA: "#4267B2",  // Blue for Meta
    JNJ: "#FF001F",   // Red for Eli Lilly
    ABBV: "#ffcc00",  // Yellow for Apple
    BAC: "#0078d7",  // Blue for Microsoft
    NFLX: "#e31937",  // Red for Tesla
    KO: "#ff9900",  // Orange for Amazon
    CRM: "#76b900",  // Green for Nvidia
    MRK: "#4267B2",  // Blue for Meta
    CVX: "#FF001F",   // Red for Eli Lilly
    AMD: "#FF001F"   // Red for Eli Lilly
  // Add more companies and their corresponding colors
};


  const initialTickers = {
    AAPL: { s: "AAPL", p: null, t: 0, marketCap: null },
    MSFT: { s: "MSFT", p: null, t: 0, marketCap: null },
    TSLA: { s: "TSLA", p: null, t: 0, marketCap: null },
    NVDA: { s: "NVDA", p: null, t: 0, marketCap: null },
    META: { s: "META", p: null, t: 0, marketCap: null },
    GOOG: { s: "GOOG", p: null, t: 0, marketCap: null },
    AMZN: { s: "AMZN", p: null, t: 0, marketCap: null },
    TSM: { s: "TSM", p: null, t: 0, marketCap: null },
    AVGO: { s: "AVGO", p: null, t: 0, marketCap: null },
    LLY: { s: "LLY", p: null, t: 0, marketCap: null },
    WMT: { s: "WMT", p: null, t: 0, marketCap: null },
    JPM: { s: "JPM", p: null, t: 0, marketCap: null },
    NVO: { s: "NVO", p: null, t: 0, marketCap: null },
    V: { s: "V", p: null, t: 0, marketCap: null },
    UNH: { s: "UNH", p: null, t: 0, marketCap: null },
    XOM: { s: "XOM", p: null, t: 0, marketCap: null },
    ORCL: { s: "ORCL", p: null, t: 0, marketCap: null },
    MA: { s: "MA", p: null, t: 0, marketCap: null },
    PG: { s: "PG", p: null, t: 0, marketCap: null },
    HD: { s: "HD", p: null, t: 0, marketCap: null },
    COST: { s: "COST", p: null, t: 0, marketCap: null },
    JNJ: { s: "JNJ", p: null, t: 0, marketCap: null },
    ABBV: { s: "ABBV", p: null, t: 0, marketCap: null },
    BAC: { s: "BAC", p: null, t: 0, marketCap: null },
    NFLX: { s: "NFLX", p: null, t: 0, marketCap: null },
    KO: { s: "KO", p: null, t: 0, marketCap: null },
    CRM: { s: "CRM", p: null, t: 0, marketCap: null },
    MRK: { s: "MRK", p: null, t: 0, marketCap: null },
    CVX: { s: "CVX", p: null, t: 0, marketCap: null },
    AMD: { s: "AMD", p: null, t: 0, marketCap: null }
  };


  const StockMarketTracker = () => {
    const [stocks, setStocks] = useState(Object.values(initialTickers));
    const [previousMarketCap, setPreviousMarketCap] = useState({});
    const [previousMarketCapChange, setPreviousMarketCapChange] = useState({});
    const [previousRanks, setPreviousRanks] = useState({});
    const [rankLoaded, setRankLoaded] = useState(false); // 
    
    const sharesOutstandingMap = {
      META: 2530000000,
      MSFT: 7433000000,
      TSLA: 3191000000,
      AMZN: 10500000000,
      GOOG: 12310000000,
      AAPL: 15287521000,
      NVDA: 24578000000,
      LLY: 900900000,
      COST: 443340000,
      V: 1610000000,
      WMT: 8044000000,
      AVGO: 4663000000,
      UNH: 921000000,
      NVO: 4457700000,
      XOM: 4317000000,
      JPM: 2889800000,
      HD: 990000000,
      ORCL: 2761000000,
      PG: 2360500000,
      MA: 933000000,
      TSM: 5185514310,
      JNJ: 2406800000,
      ABBV: 1766000000,
      BAC: 7865000000,
      NFLX: 430900000,
      KO: 4339000000,
      CRM: 984000000,
      MRK: 2542000000,
      CVX: 1824842000,
      AMD: 1618000000
    };


    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('https://cheat-6bc2732715c1.herokuapp.com/ticker');
        const data = await response.json();
        
        if (Array.isArray(data)) {
          setStocks((prevStocks) => {
            const stockMap = { ...prevStocks.reduce((acc, stock) => {
              acc[stock.s] = stock;
              return acc;
            }, {}) };

            data.forEach(item => {
              const previousPrice = stockMap[item.s]?.initialPrice || item.p; // Set initial price if not available
              stockMap[item.s] = {
                ...item,
                marketCap: (sharesOutstandingMap[item.s] || 0) * item.p,
                initialPrice: stockMap[item.s]?.initialPrice || item.p, // Track the initial price for the day
                priceChange: item.p - previousPrice // Calculate price change based on initial price
              };

                          // Store the previous market cap and market cap change
              setPreviousMarketCap((prev) => ({
                ...prev,
                [item.s]: stockMap[item.s].marketCap || 0,
              }));

              setPreviousMarketCapChange((prev) => ({
                ...prev,
                [item.s]: (sharesOutstandingMap[item.s] * stockMap[item.s].priceChange) / 1e9 || 0,
              }));
            });

            // Ensure all initial tickers are present with placeholders if necessary
            Object.keys(initialTickers).forEach(ticker => {
              if (!stockMap[ticker]) {
                stockMap[ticker] = initialTickers[ticker];  // Add placeholder if stock data is missing
              }
            });

            const updatedStocks = Object.values(stockMap).sort((a, b) => b.marketCap - a.marketCap);
            
            const currentRanks = updatedStocks.reduce((acc, stock, index) => {
              acc[stock.s] = index + 1;
              return acc;
            }, {});

            if (rankLoaded) {
              setPreviousRanks((prev) => {
                const rankChanges = { ...prev };
                updatedStocks.forEach((stock, index) => {
                  if (prev[stock.s] !== undefined) {
                    rankChanges[stock.s] = prev[stock.s] - (index + 1);
                  }
                });
                return rankChanges;
              });
            } else {
              setRankLoaded(true); // Mark rank as loaded after the first fetch
            }

            return updatedStocks;
          });
        }
      };


      fetchData();
      const interval = setInterval(fetchData, 1000);
      return () => clearInterval(interval);
    }, [rankLoaded]);

      const getBorderColor = (priceChange) => {
        if (priceChange > 0) {
          return `linear-gradient(to right, #00FF00, #00FF00 100%, #111)`; // Positive price change
        } else if (priceChange < 0) {
          return `linear-gradient(to right, #FF0000, #FF0000 100%, #111)`; // Negative price change
        } 
        return '#8F8F8F'; // Gray color when priceChange is 0
      };

        const getRankChangeIndicator = (rankChange) => {
          if (rankChange > 0) {
            return <span className="rank-up">▲ {rankChange}</span>;
          } else if (rankChange < 0) {
            return <span className="rank-down">▼ {Math.abs(rankChange)}</span>;
          } else {
            return <span className="rank-neutral">●</span>;
          }
        };


    return (
      <div>
        <div className="market-grid">
          {stocks.map((stock, index) => (
            <motion.div
              key={stock.s}
              className="company-border-container"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              style={{
                background: 'transparent',  // No background by default
                '--border-color': getBorderColor(stock.priceChange || 0),  // Set border color based on price change
                '--border-gradient': stock.priceChange > 0 ? 'var(--positive-gradient)' : 'var(--negative-gradient)',  // Set gradient based on price change
                '--hover-color': colorMap[stock.s] || '#111',  // Set the hover color dynamically for each company
              }}
            >
              <div className="company-card">
                  {index === 0 && (
                    <img src={crownIcon} alt="Crown Icon" className="crown-icon" /> // Show crown for the #1 rank
                  )}
                <div className="rank">{index + 1}</div>
                <img src={logoMap[stock.s]} alt={`${stock.s} logo`} className="company-logo" />
                <div className="company-details">
                  <p className="market-cap" style={{ color: getBorderColor(stock.priceChange || 0) }}>
                    {typeof stock.marketCap === 'number' 
                      ? (stock.marketCap >= 1e12 
                        ? `$${(stock.marketCap / 1e12).toFixed(2)}T` 
                        : `$${(stock.marketCap / 1e9).toFixed(2)}B`) 
                      : 'N/A'}
                  </p>
                  <p className="market-cap-change">
                    {typeof stock.priceChange === 'number' && typeof sharesOutstandingMap[stock.s] === 'number'
                      ? `${((sharesOutstandingMap[stock.s] * stock.priceChange) / 1e9).toFixed(2)} Bn`
                      : 'N/A'}
                  </p>
                  <p className="rank-change-indicator">
                  {getRankChangeIndicator(previousRanks[stock.s])}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div> // <- Close the outer div here
    );
  };

  export default StockMarketTracker;